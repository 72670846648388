import { createRouter, createWebHistory } from "vue-router";
import { UserRoles } from "@/enums/enums";
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "registrationPage" */ '../views/RegistrationView.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: () => import(/* webpackChunkName: "privacyPolicy" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/favorite-courses',
    name: 'favoriteCourse',
    component: () => import(/* webpackChunkName: "favoriteCourse" */ '../views/FavoriteCourse.vue')
  },
  {
    path: '/terms-of-service',
    name: 'termsOfService',
    component: () => import(/* webpackChunkName: "termsOfService" */ '../views/TermsOfService.vue')
  },
  {
    path: '/home',
    name: 'homePage',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "homePage" */ '../views/HomePage.vue')
  },
  {
    path: '/user-email-confirm',
    name: 'userEmaiConfirm',
    component: () => import(/* webpackChunkName: "userEmaiConfirm" */ '../views/ConfirmUserEmailToken.vue')
  },
  {
    path: '/set-forgot-password',
    name: 'setForgotPassword',
    component: () => import(/* webpackChunkName: "setForgotPassword" */ '../views/SetForgotPassword.vue')
  },
  {
    path: '/category/:slug',
    name: 'categoryPage',
    component: () => import(/* webpackChunkName: "categoryPage" */ '../views/CategoryPage.vue')
  },
  {
    path: '/:slug',
    name: 'coursePage',
    component: () => import(/* webpackChunkName: "coursePage" */ '../views/CourseView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/profile/edit',
    name: 'profileEdit',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "profileEdit" */ '../views/ProfileView.vue')
  },
  {
    path: '/sign-up',
    name: 'registrationPage',
    component: () => import(/* webpackChunkName: "registrationPage" */ '../views/RegistrationView.vue')
  },
  {
    path: '/login',
    name: 'loginPage',
    component: () => import(/* webpackChunkName: "loginPage" */ '../views/LoginView.vue')
  },
  {
    path: '/setting-up',
    name: 'settingUp',
    component: () => import(/* webpackChunkName: "settingUp" */ '../views/SettingUpView.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashboardPage.vue')
  },
  {
    path: '/video',
    name: 'video',
    component: () => import(/* webpackChunkName: "video" */ '../views/VideoPage.vue')
  },
  {
    path: '/requests',
    name: 'requests',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "requests" */ '../views/RequestsPage.vue')
  },
  {
    path: '/career/companies',
    name: 'companies',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "companies" */ '../views/CompaniesPage.vue')
  },
  {
    path: '/request/:id',
    name: 'request',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "request" */ '../views/RequestDetailPage.vue')
  },
  {
    path: '/career/profile',
    name: 'careerProfile',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "careerProfile" */ '../views/CareerProfile.vue')
  },
  {
    path: '/career/jobs',
    name: 'careerJobs',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "careerJobs" */ '../views/CareerJobsPostings.vue')
  },
  {
    path: '/user/:id',
    name: 'userProfile',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "userProfile" */ '../views/UserProfile.vue')
  },
  {
    path: '/company/:id',
    name: 'companyProfile',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "companyProfile" */ '../views/CompanyProfile.vue')
  },
  {
    path: '/company/create',
    name: 'createCompany',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "createCompany" */ '../views/CreateCompany.vue')
  },
  {
    path: '/search',
    name: 'dashboardSearch',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "dashboardSearch" */ '../views/DashboardSearchResult.vue')
  },
  {
    path: '/chat',
    name: 'chat',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "chat" */ '../views/ChatsPage.vue')
  },
  {
    path: '/saved-jobs',
    name: 'savedGobsPage',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "savedGobsPage" */ '../views/SavedJobsPage.vue')
  },
  {
    path: '/video/courses',
    name: 'videoCourses',
    component: () => import(/* videoCourses: "videoCourses" */ '../views/VideoPage.vue')
  },
  {
    path: '/video/show',
    name: 'videoShow',
    component: () => import(/* webpackChunkName: "videoShow" */ '../views/VideoPage.vue')
  },
  {
    path: '/career/profile/settings/general-information',
    name: 'settingsGeneralInformation',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "settingsGeneralInformation" */ '../views/SettingsGeneralInformation.vue')
  },
  {
    path: '/career/profile/settings/preferences',
    name: 'settingsPreferences',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "settingsPreferences" */ '../views/SettingsPreferences.vue')
  },
  {
    path: '/career/profile/settings/resume',
    name: 'settingsResume',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "settingsResume" */ '../views/SettingsResume.vue')
  },
  {
    path: '/career/members',
    name: 'membersScreen',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "membersScreen" */ '../views/MembersScreen.vue')
  },
  {
    path: '/admin/courses',
    name: 'AdminCourses',
    meta: {
      requiresAuth: true,
      roles: [UserRoles.admin],
    },
    component: () => import(/* webpackChunkName: "AdminCourses" */ '../views/admin/AdminCourses.vue'),
  },
  {
    path: '/admin/members',
    name: 'AdminMembers',
    meta: {
      requiresAuth: true,
      roles: [UserRoles.admin],
    },
    component: () => import('../views/admin/AdminMembers.vue'),
  },
  {
    path: '/admin/jobs',
    name: 'AdminJobs',
    meta: {
      requiresAuth: true,
      roles: [UserRoles.admin],
    },
    component: () => import('../views/admin/AdminJobs.vue'),
  },
  {
    path: '/admin/create-course',
    name: 'CreatCourse',
    meta: {
      requiresAuth: true,
      roles: [UserRoles.admin],
    },
    component: () => import(/* webpackChunkName: "CreatCourse" */ '../views/admin/CreatCourse.vue'),
  },
  {
    path: '/admin/create-show',
    name: 'CreatCourseShow',
    meta: {
      requiresAuth: true,
      roles: [UserRoles.admin],
    },
    component: () => import(/* webpackChunkName: "CreatCourseShow" */ '../views/admin/CreatCourseShow.vue'),
  },
  {
    path: '/admin/course/:id',
    name: 'Course',
    meta: {
      requiresAuth: true,
      roles: [UserRoles.admin],
    },
    component: () => import(/* webpackChunkName: "Course" */ '../views/admin/CourseView.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active-link',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const user = JSON.parse(localStorage.getItem('panagenius_user'));
  const jwt = localStorage.getItem('panagenius_jwt');

  if (!requiresAuth) {
    next();
    return;
  }

  if (!user || !jwt) {
    next({ path: '/',query: { login: 'true' }, params: { nextUrl: to.fullPath } });
    return;
  }

  const hasRequiredRole = to.meta.roles ? to.meta.roles.includes(user.role) : true;
  if (hasRequiredRole) {
    next();
  } else {
    next({ path: '/' });
  }
});

export default router;
